<template>
  <v-container id="DeviceView" class="px-8" fluid>
    <v-row>
      <v-col cols="12">
        <div class="d-flex justify-space-between">
          <div>
            <div class="text-h5 font-weight-bold">Device List</div>
            <div class="text-subtitle-1 font-weight-medium">
              Total {{ total_devices }} devices
            </div>
          </div>
          <v-btn
            small
            depressed
            color="primary"
            @click.prevent="navigateToCreateDevices()"
          >
            <v-icon left> mdi-plus </v-icon>
            New Device
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          class="elevation-1 cursor-pointer"
          :headers="headers"
          :items="device_list"
          :items-per-page="10"
          :footer-props="{ 'disable-items-per-page': true }"
          @click:row="rowDataSelected"
          @pagination="paginationUpdated"
        >
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="deleteActionClicked(item)"
                >
                  <v-icon small>mdi-trash-can-outline</v-icon>
                </v-btn>
              </template>
              <span> Remove Device</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "DeviceView",
  data() {
    return {
      headers: [
        { text: "ID", value: "_id" },
        {
          text: "Device Name",
          align: "start",
          value: "deviceName",
        },
        {
          text: "Device Type",
          align: "center",
          value: "deviceType",
        },
        { text: "Actions", align: "center", value: "actions" },
      ],
    };
  },
  computed: {
    ...mapState({
      device_list: (state) => state.device.device_list,
      total_devices: (state) => state.device.total_devices,
      current_page: (state) => state.device.current_page,
    }),
  },
  methods: {
    ...mapActions({
      requestGetDeviceList: "requestGetDeviceList",
    }),
    ...mapMutations({
      resetDeviceState: "RESET_DEVICE_STATE",
      setFilterPage: "SET_DEVICE_FILTER_PAGE",
      displayDeleteDialog: "DISPLAY_DELETE_DIALOG",
    }),
    rowDataSelected(row) {
      this.navigateToDeviceDetail(row._id);
    },
    deleteActionClicked(item) {
      const params = {
        id: item._id,
        name: item.deviceName,
        type: "device",
      };
      this.displayDeleteDialog(params);
    },
    paginationUpdated(pagination) {
      if (
        pagination.page === pagination.pageCount &&
        pagination.pageStop < this.total_devices
      ) {
        this.setFilterPage(this.current_page + 1);
        this.requestGetDeviceList();
      }
    },
    navigateToCreateDevices() {
      this.$router.push({ name: "device-create" });
    },
    navigateToDeviceDetail(device_id) {
      this.$router.push({ name: "device-detail", params: { id: device_id } });
    },
  },
  beforeMount() {
    this.requestGetDeviceList();
  },
  beforeRouteLeave(to, from, next) {
    this.resetDeviceState();
    next();
  },
};
</script>
